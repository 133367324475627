@import url(//fonts.googleapis.com/css?family=Inconsolata);
@import url(//fonts.googleapis.com/css?family=Syne);

* {
  font-family: 'Inconsolate Mono', monospace;
  font-weight: 500;
}




body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Syne', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
